import * as React from "react"
import Header from './Header/Header';
import Footer from './Footer/Footer'
import ChatIcons from "./ChatIcons";

const Layout = ({ children }) => {
  /*
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }
*/
  return (
    <div>
      <Header />
      <main style={{height:"100%",minHeight:"100vh"}}>
        {children}
        <ChatIcons />
        </main>
      <Footer />
    </div>
  )
}

export default Layout
