import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Grid, Slide } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../assets/Asset4.png';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { useStyles } from '../../theme/theme';
import './Header.css'
import * as Scroll from 'react-scroll';



const Header = () => {

  const matches = useMediaQuery('(max-width:1165px)');
  const [isSticky, setSticky] = useState(false);
  const classes = useStyles();

  const [click, setClick] = React.useState(false);

  const handleClick = () => {
    setClick(!click)
  }

  const anchorRef = React.useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setClick(!click);
  };

  /*
  
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }
  
      prevOpen.current = open;
    }, [open]);
      
  */
  const useOutsideClick = (ref) => {

    const [clickOutside, setClickOutside] = useState(false);
    const handleClick = (e) => {

      ref.current?.contains(e.target)
        ? setClickOutside(false)
        : setClickOutside(true);

    };
    useEffect(() => {

      document.addEventListener('mousedown', handleClick);

      return () => document.removeEventListener('mousedown', handleClick);

    }, []);


    return clickOutside;

  }
  const menuRef = useRef();
  const outsideClick = useOutsideClick(menuRef);
  const handleScroll = () => {
    /*
  if (ref.current) {
    setSticky(ref.current.getBoundingClientRect().top <= 0);
  }
  */
    if (window.pageYOffset > 0) {
      setSticky(true)
    } else {
      setSticky(false)
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);



  return (
    <header className={`${classes.site_header} ${isSticky ? classes.box_shad : ''}`}>
      <Grid container direction="row"
        justify="space-evenly"
        alignItems="center" >
        <Grid item md={4} sm={5} xs={5} >
          <Link to="/" className={classes.Logo}><img src={Logo} /></Link>
        </Grid>
        <Grid item md={6} sm={6} xs={5} className="menu" style={{ textAlign: "right" }}>


          {matches ? (<div><Button style={{ color: "#3d55a4" }} variant="primary" ref={menuRef} onClick={handleClick}>{click && !outsideClick ? <MenuOpenIcon style={{ fontSize: '32px' }} /> : <MenuIcon style={{ fontSize: '32px' }} />}</Button>
            <div className={`${click ? classes.show : classes.hide} `}>


              <ul className={classes.mobile_menu} >
                <li onClick={handleClose}><Link to="/" >Home</Link></li>
                <li onClick={handleClose}><AnchorLink to="/about-us" >About</AnchorLink></li>
                <li onClick={handleClose}><AnchorLink to="/products">Our Products</AnchorLink></li>
                {/* <li onClick={handleClose}><AnchorLink to="/#production" stripHash={false}>Production</AnchorLink></li> */}
                <li className={classes.btn_variant_1} onClick={handleClose}><AnchorLink to="#contact-us" title="Contact us" stripHash className="stripped" /></li>
              </ul>

            </div></div>) :
            <ul className={classes.desktop_menu}>
              <li><Link to="/">Home</Link></li>
              <li><AnchorLink to="/about-us">About</AnchorLink></li>
              <li><Link to="/products">Our Products</Link></li>
              {/* <li><AnchorLink to="/#production">Production</AnchorLink></li> */}
              <li className={classes.btn_variant_1}><AnchorLink to="#contact-us" title="Contact us" className="stripped" /></li>
            </ul>
          }
        </Grid>
      </Grid>
    </header>
  )
}

export default Header;