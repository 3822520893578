import { makeStyles } from '@material-ui/core/styles';
import ProductBanner from '../assets/products/ProductsBanner.png';
import FeaturedProduct from '../assets/products/featured_products.png';
import RelatedBanner from '../assets/products/related-banner.png';
import ThaiOffice from '../assets/about/thailandOffice.png'
import Thankyou from '../assets/Note.png';
export const useStyles = makeStyles((theme) => ({

    //------------------------------------------------------------------------------------------------------------------//  
    /* Header */
    //------------------------------------------------------------------------------------------------------------------// 
    Logo: {
        "& > img": {
            width: "180px",
            height: "auto",
            [theme.breakpoints.down(768)]: {
                width: "95px",
                height: "auto",
                marginTop: "5px",
            }
        }

    },
    site_header: {
        padding: "10px 0px",
        position: "sticky",
        top: "0px",
        width: "100%",
        zIndex: "999",
        backgroundColor: "#fff",
        transition: "1s ease-in-out"
    },
    box_shad: {
        boxShadow: "0px 10px 20px #0000000c",
        transition: "0.5s ease-out",
    },
    desktop_menu: {
        listStyle: "none",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        margin: "0px",
        paddingLeft: "0px",
        "& > li": {
            marginBottom: "0px",
            fontWeight: "500",
            fontFamily: "'Roboto',sans-serif",
            fontSize: "16px",
            color: "#1E6DB5",
        },
        "& > li:not(:last-child)": {
            marginRight: "50px",
            color: "#3D55A4",
        },
        "& > li:last-child": {
            color: "#fff",
        }
    },

    mobile_menu: {
        display: "block",
        position: "absolute",
        backgroundColor: "#fff",
        width: "100%",
        left: "0px",
        padding: "50px",
        listStyle: "none",
        textAlign: "center",
        height: "100vh",
        [theme.breakpoints.down(768)]: {
            marginTop: "-5px"
        },
        "& > li": {
            marginBottom: "10px",
            fontSize: "16px",
            fontFamily: "'Roboto',sans-serif",
            fontWeight: "500",
            color: "#3D55A4",
        },
        "& > li:last-child": {
            color: "#fff",
        },

    },

    show: {
        display: "block",
    },
    hide: {
        display: "none",
    },
    //------------------------------------------------------------------------------------------------------------------//  
    /* Home Page */
    //------------------------------------------------------------------------------------------------------------------//  
    mySwiper1: {
        width: "1200px",
    },
    btn_variant_1: {
        background: `linear-gradient(
        -135deg
        ,#1e6db5, #3d55a4)`,
        color: "#fff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "5px",
        fontSize: "16px",
        fontWeight: "600",
        cursor: "pointer",
        outline: "none",
        "&:hover": {
            "& > .arrow_icon": {
                animation: `$myEffect 2000ms infinite`,
            }
        }
    },
    "@keyframes myEffect": {
        "0%": {
            opacity: "0",
            transform: "translateX(0px)"
        },
        "50%": {
            opacity: "1",
            transform: "translateX(5px)"
        },
        "100%": {
            opacity: "0",
            transform: "translateX(0px)"
        }
    },
    hero_section: {
        padding: " 90px 0px 90px 0px",

        [theme.breakpoints.down('xs')]: {
            padding: " 50px 0px 50px 0px",
        }
    },
    hero_section_content: {
        "& > span": {
            fontSize: "20px",
            fontFamily: "Poppins , sans-serif",
            fontWeight: "600",
            color: "#3D55A4",
            [theme.breakpoints.down('xs')]: {
                textAlign: "center",
            }
        },
        "& > h1": {
            marginTop: "0px",
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
            }
        }

    },
    hero_section_image: {
        "& > img": {
            width: "100%",
            maxWidth: "100%",
            height: "auto",
        },
        [theme.breakpoints.down('xs')]: {
            display: "none",
        }
    },
    about_section: {
        padding: "0px 0px 30px 0px",
        backgroundColor: "#FFFFFF",
        "&>:nth-child(1)": {

            [theme.breakpoints.down('sm')]: {
                marginRight: "0px",
            },
        },

    },
    about_content: {
        backgroundColor: "transparent",
        borderRadius: "15px",
        transition: "0.2s ease-in",


        "& > p": {
            fontSize: "18px",
            fontFamily: 'Poppins',
            color: "grey",
            textAlign: "center",
        },
        "& > h3": {
            color: " #3D55A4",
            fontSize: "48px",
            textAlign: "center",
            fontFamily: "Roboto ,sans-serif",
            fontWeight: "700",
            marginBottom: "0px",
            marginTop: "0px",

            [theme.breakpoints.down(1300)]: {
                fontSize: "40px",

            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "32px",
            },
        },
        "& > h2": {
            marginTop: "0px",
            marginBottom: "18px",
            lineHeight: "1",
            fontSize: "24px",
            textAlign: "center",
            textTransform: "uppercase",
            [theme.breakpoints.down(1300)]: {
                fontSize: "24px",
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "20px",
            },
        },

        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },
    video_container: {
        position: "relative",
        width: "100%",
        paddingBottom: " 56.25%",
        "& > .video": {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            border: "0",
        }
    },
    process_section: {
        "& > .title": {
            textAlign: "center",
            margin: "0px",
            [theme.breakpoints.down(865)]: {
                marginBottom: "0px",
            },
            "& > h3": {
                margin: "0px",
            }
        },

        padding: "20px 0px 20px 0px",
        backgroundColor: "#F7F8FB",
        [theme.breakpoints.down(865)]: {
            padding: "0px 20px",
        }
    },
    process_section_container_new: {
        backgroundImage: "linear-gradient(180deg, #7788c0, #1f364c)",
        display: 'flex',
        justifyContent: 'center',
        margin: "10px 0",
        "& > h3": {
            color: "#FFFFFF",
            fontSize: "48px",
            textAlign: "center",
            fontFamily: "Roboto ,sans-serif",
            fontWeight: "900",
            marginBottom: "25px",
        },
    },
    process_section_new: {
        padding: "0px 60px",
        margin: "50px 10px",
        // display: "flex",
        gap: "60px",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down(480)]: {
            padding: "0px 10px",
        },

    },

    process_card_new: {
        background: "#FFFFFF",
        padding: "40px 20px",
        borderRadius: "10px",
        width: "400px",
        maxHeight: "425px",
        height: "100%",
        [theme.breakpoints.down(480)]: {
            height: "fit-content",
            maxHeight: "710px",
        },
        [theme.breakpoints.down(1500)]: {
            height: "fit-content",
            maxHeight: "465px",
        },
        [theme.breakpoints.down(1400)]: {
            height: "100%",
            maxHeight: "545px",
        },
        [theme.breakpoints.down(1280)]: {
            height: "100%",
            maxHeight: "430px",
        },


        "& > h4": {

            fontSize: "24px",
            fontFamily: 'Poppins',
            fontWeight: "500",
            color: "#3D55A4",
            marginBottom: "0px",
        },

        "& > p": {
            color: " #000000a3",
            fontFamily: 'Poppins',
            fontSize: "16px",
            fontWeight: "300",

        }
    },
    process_section_xs: {
        padding: "50px 30px",

        backgroundColor: "#F7F8FB",
        "& > .title": {
            "& > h3": {
                margin: "0px",
            }

        }
    },
    process_image_xs: {
        "& > img": {
            width: "100%",
            height: "400px",
            objectFit: "cover",
            borderRadius: "25px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",

        },
    },
    process_image: {
        "& > img": {
            width: "100%",
            maxWidth: "800px",
            height: "700px",
            objectFit: "cover",
            borderRadius: "50px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            [theme.breakpoints.down('sm')]: {
                objectFit: "cover",
                height: "500px",
                width: "100%",

            },
        },
        // order:1,
        // [theme.breakpoints.down('md')]:{
        //     order:0

        // },


        /*
        [theme.breakpoints.down(865)]:{
            width:"100%",
            display:'block',
        }*/
    },
    process: {

    },


    steps: {
        "& > $step": {
            "& > step-heading": {
                color: "#000000a3",
                fontFamily: 'Poppins',
                fontSize: "18px",
                fontWeight: "600",
            },
            "& > .circle": {
                width: "75px",
                height: "75px",
                borderRadius: "50%",
                boxShadow: " 1px 10px 20px #00000038",
                position: "relative",
                border: "none",
                backgroundColor: "#fff",
                outline: "none",
                "& > :focus": {
                    backgroundColor: "#3D55A4",
                    color: "#fff",
                    "& > span": {
                        color: "#fff",
                    }
                },
                "& > span": {
                    position: "absolute",
                    top: "20px",
                    left: "30px",
                    color: "#00000052",
                    fontSize: "24px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                },
                [theme.breakpoints.down("sm")]: {
                    display: "none",
                },
            },
            "& > step-content": {
                color: "#000000a3",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: "300",
            },
            "& > nth-child(4n+1)": {
                marginBottom: "80px",
            },
            "& > :nth-child(4n+1) .circle": {
                marginLeft: "auto",
            },
            "& > :nth-child(4n+2) .circle": {
                marginLeft: "auto",
            }

        },
        textAlign: "right",
        // order:0,
        // [theme.breakpoints.down('md')]:{
        //     order:1,
        //     textAlign:"left"
        // },
        [theme.breakpoints.down(865)]: {

        }
    },
    step_2: {

        // order:2,
        // [theme.breakpoints.down('up')]:{
        //     order:2,
        // },
        [theme.breakpoints.down(1024)]: {
            textAlign: "left"
        }
    },
    export_section: {
        padding: "50px 0px",
        backgroundColor: "#F7F8FB",
        [theme.breakpoints.down('sm')]: {
            padding: "0px 0px 0px 0px",
        },
        "& > .title": {
            textAlign: "center",
            margin: "0px",
            "& > h2": {
                margin: "0px",
            },
            "& > p": {
                color: " #000000a3",
                fontFamily: 'Poppins',
                fontSize: "20px",
                fontWeight: "300",
                padding: "0 280px",
                [theme.breakpoints.down(1366)]: {
                    padding: "0px 50px",
                },
            }
        },


    },
    export_countries: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: 'wrap',
    },
    country: {
        textAlign: "center",
        padding: "40px",
        marginTop: "50px",
        width: "100%",
        maxWidth: "400px",
        backgroundColor: "#fff",
        marginLeft: "auto",
        marginRight: "auto",
        transition: "0.5s ease",
        "&:hover": {
            boxShadow: "1px 10px 20px #0000002b",
            transform: "scale(1.1)",
        },
        [theme.breakpoints.down(1650)]: {
            maxWidth: "300px",
        },
        [theme.breakpoints.down("md")]: {
            maxWidth: "400px",
        },
        [theme.breakpoints.down("sm")]: {
            maxWidth: "300px",
        },

        "& > h4": {
            textAlign: "center",
            fontSize: "24px",
            fontFamily: 'Poppins',
            fontWeight: "400",
            color: "#3D55A4",
            marginBottom: "0px",
        },
        "& > img": {
            width: "100%",
            maxWidth: "300px",
            height: "300px",
            borderRadius: "50%",
            objectFit: "cover",
            [theme.breakpoints.down(1650)]: {
                maxWidth: "200px",
                height: "200px",
            },
            [theme.breakpoints.down("md")]: {
                maxWidth: "300px",
                height: "300px",
            },
            [theme.breakpoints.down("sm")]: {
                maxWidth: "200px",
                height: "200px",
            },
            [theme.breakpoints.down(400)]: {
                width: '100%',
                maxWidth: '200px',
                height: '200px',
            }
        },
    },
    testomonial_section: {
        padding: "50px 0px 0px 0px",
        background: `linear-gradient(
        180deg
        , #F7F8FB 60%, #fff 50%)`,
        "& > .title": {
            textAlign: "center",
            marginBottom: "50px",
            "& > h3": {
                margin: "0px",
            }
        },
        "& > .title p": {
            color: "#000000a3",
            fontFamily: 'Poppins',
            fontSize: "20px",
            fontWeight: "300",
            [theme.breakpoints.down(1366)]: {
                padding: "0px 50px",
            },
        }

    },
    testomonial_carousel: {
        position: "relative",
    },
    avatar: {
        "& > img": {
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            objectFit: "cover",
            [theme.breakpoints.down(400)]: {
                width: "30px",
                height: "30px",
                borderRadius: "50%",
            },
        },

        "& > h5": {
            fontSize: "24px",
            fontFamily: 'Poppins',
            fontWeight: "700",
            color: "#3D55A4",
            marginBottom: "0px",
            marginTop: "15px",
            [theme.breakpoints.down(400)]: {
                fontSize: "20px",
            },
        },
        "& > p": {
            color: "#000000a3",
            fontFamily: 'Poppins',
            fontSize: "18px",
            fontWeight: "600",
            marginTop: "0px",
            [theme.breakpoints.down(400)]: {
                fontSize: "16px",
            },
        }

    },
    nex_icon: {
        width: "75px",
        height: "75px",
        borderRadius: "50%",
        outline: "none",
        border: "none",
        backgroundColor: "#3D55A4",
        color: " #fff",
        zIndex: "9999999999",
        position: "absolute",
        top: "175px",
        right: "0px",
    },
    next_icon_svg: {
        textAlign: "center",
    },
    prev_icon: {
        width: "75px",
        height: "75px",
        borderRadius: "50%",
        outline: "none",
        border: "none",
        backgroundColor: "#3D55A4",
        color: "#fff",
        zIndex: "9999999999",
        position: "absolute",
        left: "0px",
        top: "175px",
    },
    prev_icon_svg: {
        textAlign: "center",
        marginLeft: "10px",
    },
    review: {
        width: "800px",
        padding: "50px",
        margin: " 0px auto",
        boxShadow: "1px 1px 20px #0000002e",
        marginBottom: "90px",
        textAlign: "center",
        backgroundColor: "#fff",
        [theme.breakpoints.down(1230)]: {
            width: "600px"
        },
        [theme.breakpoints.down(960)]: {
            width: "500px"
        },
        [theme.breakpoints.down(670)]: {
            width: "400px",
        },
        [theme.breakpoints.down(520)]: {
            width: "350px",
            padding: "20px",
        },
        [theme.breakpoints.down(400)]: {
            width: "300px",
            padding: "10px",
        },
        [theme.breakpoints.down(350)]: {
            width: "260px",
            padding: "10px",
        },
        "& > .feedback": {
            fontFamily: 'Poppins',
            fontWeight: "500",
            fontSize: "22px",
            color: "#3D55A4",
            fontStyle: "italic",
            [theme.breakpoints.down(400)]: {
                fontSize: "18px",
            },
            [theme.breakpoints.down(350)]: {
                fontSize: "16px",
            },
        },
    },
    last_name: {
        paddingLeft: "20px",
        width: "100%",
        height: "60px",
        outline: "none",
        borderRadius: "5px",
        border: "1px solid #E0E0E0",

        "&:focus": {
            boxShadow: "1px 5px 20px #00000059",
            border: "none",
        }
    },
    first_name: {
        paddingLeft: "20px",
        outline: "none",
        width: "100%",
        height: "60px",
        border: "1px solid #E0E0E0",
        borderRadius: "5px",
        transition: "0s ease",
        "&:focus": {
            boxShadow: "1px 5px 20px #00000059",
            border: "none",
        }
    },
    email_address: {
        paddingLeft: "20px",
        outline: "none",
        height: "60px",
        border: "1px solid #E0E0E0",
        borderRadius: "5px",
        width: "100%",
        transition: "0s ease",
        "&:focus": {
            boxShadow: "1px 5px 20px #00000059",
            border: "none",
        }
    },
    form: {
        width: "600px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
    },
    message_box: {
        paddingLeft: "20px",
        paddingTop: "20px",
        outline: "none",
        borderRadius: "5px",
        fontSize: "16px",
        fontFamily: "Roboto,sans-serif",
        width: '100%',
        border: "1px solid #E0E0E0",
        transition: "0s ease",
        "&:focus": {
            boxShadow: "1px 5px 20px #00000059",
            border: "none",
        }
    },

    map_image: {
        position: "relative",
        overflow: "hidden",
        clipPath: "polygon(0 14%, 100% 14%, 100% 100%, 0% 100%)",
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            display: "none",
        }

    },
    map: {
        width: "100%",
        height: "690px",
        objectFit: "contain",
    },
    map_box: {
        position: "absolute",
        width: "300px",
        height: "200px",
        objectFit: "contain",
        top: "180px",
        left: "30%",

    },
    contact_form: {
        width: "fit-content",
        margin: "0 auto",
        "& > h3": {
            textAlign: "center",
            marginBottom: "50px",
            marginTop: "0px",
        }
    },
    contact_section: {
        marginBottom: "50px",
        position: "relative",
        paddingBottom: "150px",
        zIndex: "0",
        backgroundColor: "#f7f8fb"
    },
    btn_variant_2: {
        backgroundColor: "#3D55A4",
        border: "none",
        outline: "none",
        color: "#f9f9f9",
        padding: "15px 60px",
        fontSize: "20px",
        fontFamily: "Poppins,sans-serif",
        fontWeight: "500",
        margin: "0px auto",
        borderRadius: "5px",
        cursor: "pointer"
    },
    thai_office_container: {
        width: "100%",
        minHeight: "100vh",
    },
    thai_office: {
        perspective: "3000px",
        width: "50%",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        transformStyle: "preserve-3d",
        "& > img": {
            transform: "rotateX(70deg) rotateZ(-60deg) translate3d(-120px, 0px, 70px)",
            boxShadow: "-80px 60px 15px 5px rgba(0,0,0,0.4)",
            transition: "all .4s",
            transformStyle: "preserve-3d",
        },
        "&:hover img": {
            transform: "rotateX(0deg) rotateZ(0deg) translate3d(0px, 0px, 0px)",
            boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.0)",
        }
    },



    //------------------------------------------------------------------------------------------------------------------//  
    /* Product Page */
    //------------------------------------------------------------------------------------------------------------------//

    productBanner: {
        backgroundImage: `url(${ProductBanner})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "300px",
        textAlign: "center"
    },
    banner_content: {

        "& > h1": {
            color: "#fff",
            marginBottom: "0px",
            marginTop: "90px",

        },
        "& > p": {
            color: "#fff",
            fontSize: "18px",
            letterSpacing: "3px"
        }
    },
    products_features: {
        backgroundColor: "#fff",
        paddingTop: "50px",
        paddingBottom: "50px",
        textAlign: "center",
        position: "relative",
        [theme.breakpoints.down(1024)]: {
            textAlign: "left",
        },

    },
    feature: {
        width: "220px",
        margin: " 0 auto",
        display: "inline-flex",
        justifyContent: "center",

        [theme.breakpoints.down(1025)]: {
            justifyContent: "flex-start",
        },
        [theme.breakpoints.down(769)]: {
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%"
        },
        [theme.breakpoints.down(400)]: {
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },


        "& > p": {
            textAlign: "left",
            paddingLeft: "10px",
            [theme.breakpoints.down(400)]: {
                textAlign: "center",
                paddingLeft: "0px",
            }
        },
        "& > img": {
            width: "100%",
            maxWidth: "50px",
        },

    },

    products_body: {
        backgroundColor: "#F7F8FB",

    },

    featured_product: {
        backgroundImage: `url(${FeaturedProduct})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "300px",
        textAlign: "center"
    },
    featured_product_content: {
        "& > h3": {
            textTransform: "uppercase",
            color: "#fff",
            marginTop: "120px",
        }
    },
    featured_product_detail: {
        width: "100%",
        marginTop: "50px",
        marginBottom: "50px",
        paddingBottom: "50px",
        paddingRight: "50px",
        paddingLeft: "50px",
        [theme.breakpoints.down(500)]: {
            paddingRight: "10px",
            paddingLeft: "10px",
        }
    },
    feature_image: {
        backgroundColor: "#fff",
        width: "100%",
        height: "300px",
        overflow: "hidden",
        borderRadius: "20px",
        transition: "0.4s ease-in-out",
        "& > img": {
            width: "100%",
            height: "280px",
            objectFit: "contain"
        },
    },
    feature_title: {
        textAlign: "center",
        color: "#3D55A4",
        fontSize: "20px",
        fontFamily: "Roboto,sans-serif",
        fontWeight: "500",
        marginBottom: "5px",
        marginTop: "30px",
    },
    feature_cat: {
        textAlign: "center",
        color: "#bfc4ce",
        fontSize: "18px",
        fontFamily: "Roboto,sans-serif",
        textTransform: "capitalize",
        marginTop: "0px",
    },
    feature_product: {

        "&:hover": {
            "& $feature_image": {
                boxShadow: "5px 20px 20px 0 #0000002e;"
            }
        }
    },

    //------------------------------------------------------------------------------------------------------------------//  
    /* Product Page Tablet Mode*/
    //------------------------------------------------------------------------------------------------------------------//

    tab_image: {
        backgroundColor: "#fff",
        width: "100%",
        height: "400px",
        border: "1px solid rgba(0, 0, 0, 0.37)",
        borderRadius: "20px",
        overflow: "hidden",
        position: "relative",
        "& > img": {
            width: "100%",
            height: "380px",
            objectFit: "contain"
        },

        "&::after": {
            content: '" "',
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            background: "#3d55a4c5",
            opacity: "0",
            transition: "all 0.5s",
        },
        "&:hover::after": {
            opacity: "1",

        },

        "&:hover": {
            "& $hover_items": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                width: "100%",
                height: "100%",
                opacity: "1",
                zIndex: "9999999999999",
                position: "absolute",
                top: "0",
                color: " #fff",

            }

        }
    },
    hover_items: {
        dispay: "none"
    },
    tab_title: {
        color: "#fff",
        fontSize: "32px",
        fontFamily: "Roboto, sans-serif",
        fontWeight: "700",
        marginBottom: "0px",
    },
    view_product: {
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: "18px",
        fontFamily: "Roboto , sans-serif",
        fontWeight: "500",
        border: "1px solid #fff",
        borderRadius: "20px",
        outline: "none",
        cursor: "pointer",
        padding: "5px 30px",
        transition: " 0.5s ease",
        marginTop: "10px",
        "&:hover": {
            color: "#3d55a4",
            backgroundColor: "#fff",
        }
    },
    featured_product_detail_tab: {
        width: "100%",
        marginTop: "50px",
        marginBottom: "50px",
        paddingBottom: "50px",
        paddingRight: "10px",
        paddingLeft: "10px",
    },
    //------------------------------------------------------------------------------------------------------------------//  
    /* Single Product */
    //------------------------------------------------------------------------------------------------------------------//
    related_product_body: {
        backgroundColor: "#F7F8FB",
    },
    related_product: {
        backgroundImage: `url(${RelatedBanner})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "300px",
        textAlign: "center"
    },
    related_product_content: {
        "& > h3": {
            textTransform: "uppercase",
            color: "#fff",
            marginTop: "120px",
        }
    },
    modal_header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "20px"
    },
    modal_header_logo: {

        "& > img": {
            width: "100%",
            maxWidth: "120px"
        }
    },



    //------------------------------------------------------------------------------------------------------------------//  
    /*About Page */
    //------------------------------------------------------------------------------------------------------------------//


    about_section_header: {
        textAlign: "left",
        paddingLeft: "120px",
        "& > h1": {
            textTransform: "uppercase",
            marginBottom: "10px",
        },
        "& > p": {
            fontSize: "18px",
            fontWeight: "400",
        },
        order: 1,
        [theme.breakpoints.down(769)]: {
            order: 2,
            textAlign: "center",
            padding: "0px 20px",

        }
    },
    about_banner_image: {
        "& > img": {
            width: "100%",
            maxWidth: "1200px",
            [theme.breakpoints.down(769)]: {
                marginTop: "-20px"
            }
        },
        order: 2,
        [theme.breakpoints.down(769)]: {
            order: 1
        }
    },

    locationSwiperContainer: {
        position: "relative",
        padding: "50px",
        [theme.breakpoints.down(1440)]: {
            padding: "10px",
        }
    },
    locationSwiperContainerFactory: {
        position: "relative",
        padding: "50px",
        [theme.breakpoints.down(1440)]: {
            padding: "10px",
        },
        [theme.breakpoints.down(1280)]: {
            padding: "10px",
            flexDirection: "column-reverse"
        },
        [theme.breakpoints.down(480)]: {
            padding: "0px",
            flexDirection: "column-reverse"
        }
    },
    locationDescription: {
        padding: "50px",
        "& > p": {
            color: " #000000a3",
            fontFamily: 'Poppins',
            fontSize: "20px",
            fontWeight: "300",
            textAlign: "justify",
        },
        [theme.breakpoints.down(1440)]: {
            padding: "10px",
        },
        [theme.breakpoints.down(480)]: {
            padding: "5px",
            "& > p": {
                color: " #000000a3",
                fontFamily: 'Poppins',
                fontSize: "20px",
                fontWeight: "300",
                textAlign: "center",
            },
        },
    },
    locationSlider: {
        padding: "50px",
        [theme.breakpoints.down(1440)]: {
            padding: "10px",
        },
        [theme.breakpoints.down(480)]: {
            padding: "5px",
        },
    },
    // deliver_section:{
    //     backgroundImage:`url(${DeliverBanner})`,
    //     backgroundSize:"cover",
    //     backgroundRepeat:"no-repeat",
    //     backgroundPosition:"center",
    //     height:"fit-content",
    //     textAlign:"center",
    //     color:"#fff",
    //     borderRadius: "20px",
    //     padding:"40px",
    //     "& > h1":{
    //         color:"#fff",
    //         fontWeight:"600",
    //         textTransform:"uppercase",
    //         margin:"0px"
    //     },
    //     "& > p":{
    //     width: "100%",
    //     maxWidth: "1000px",
    //     margin:" 0 auto",
    //     }
    // },
    // pakOffice:{
    //     backgroundImage:`url(${PakOffice})`,
    //     backgroundSize:"cover",
    //     backgroundRepeat:"no-repeat",
    //     backgroundPosition:"center",
    //     height:"500px",
    //     textAlign:"center",
    //     color:"#fff",
    //     borderRadius: "20px",
    //     padding:"40px",
    //     [theme.breakpoints.down(1100)]:{
    //         padding:"40px 20px",
    //     },
    //     "& > h3":{
    //         margin:"0px",
    //         color:"#fff",
    //         textTransform:"uppercase",
    //         [theme.breakpoints.down(1530)]:{
    //             fontSize:"32px",
    //         }
    //     },
    //     "& > h2":{
    //         margin:"0px",
    //         fontSize:"32px",
    //         color:"#fff",
    //         textTransform:"uppercase",
    //         [theme.breakpoints.down(1530)]:{
    //             fontSize:"24px",
    //         }
    //     }
    // },
    thaiOffice: {
        backgroundImage: `url(${ThaiOffice})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "fit-content",
        textAlign: "center",
        color: "#fff",
        borderRadius: "20px",
        padding: "40px",
        [theme.breakpoints.down(1100)]: {
            padding: "40px 20px",
        },
        "& > h3": {
            margin: "0px",
            color: "#fff",
            textTransform: "uppercase",
            [theme.breakpoints.down(1530)]: {
                fontSize: "32px",
            }
        },
        "& > h2": {
            margin: "0px",
            fontSize: "32px",
            color: "#fff",
            textTransform: "uppercase",
            [theme.breakpoints.down(1530)]: {
                fontSize: "24px",
            }
        },
        "& > p": {
            fontSize: "18px"
        }
    },
    office_details: {
        listStyle: "none",
        padding: "0px",
        "& > li": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
            "& > p": {
                fontSize: "18px"
            }
        }
    },
    office_icon: {
        marginRight: "20px"
    },


    //------------------------------------------------------------------------------------------------------------------//  
    /* Thankyou Modal */
    //------------------------------------------------------------------------------------------------------------------//
    thankyou: {
        backgroundImage: `url(${Thankyou})`,
        textAlign: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        height: "500px",
        width: "500px",
        boxShadow: "none!important",
        backgroundColor: "transparent!important",
        [theme.breakpoints.down(500)]: {
            height: "300px",
            width: "300px",
        },
        "& > h3": {
            margin: "10px 0px",
        },
        "& > p": {
            margin: "0px 0px 10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
        }
    },
    backdrop: {
        backgroundColor: "#3d55a47d!important"
    }

}))

