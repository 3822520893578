import React from "react"
import MessengerCustomerChat from "react-messenger-customer-chat"
import { makeStyles } from "@material-ui/core/styles"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"


const useStyles = makeStyles(theme => ({
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    right: "25px",
    bottom: "25px",
    position: "fixed",
    zIndex: "9999",
    borderRadius: "50%",
    border: "none",
    outline: "none",
    cursor: "pointer",
    padding: "0px",
    backgroundColor: "transparent",
    transition: "0.7s ease-all",
    "& > img": {
      height: "60px",
      width: "60px",
      borderRadius: "50%",
      boxShadow: "-1px 3px 11px 2px #00000057",
      transition: "0.4s ease-in-out",
      "&:hover": {
        boxShadow: "-1px 3px 11px 2px #00000057",
        transform: "scale(1.1)"
      }
    },
    "& > svg": {
      backgroundColor: "#1E6DB5",
      color: "#fff",
      fontSize: "32px",
      borderRadius: "50%",
      padding: "10px",
      width: "60px",
      height: "60px",
      cursor: "pointer",
      boxShadow: "-1px 3px 11px 2px #00000057",
      transition: "0.4s ease-in-out",
      "&:hover": {
        boxShadow: "-1px 3px 11px 2px #00000057",
        transform: "scale(1.1)"
      }
    },
  },
  WhatsAppIcon: {
    zIndex: 1,
    backgroundColor: "#25d366",
    width: "60px",
    height: "60px",
    position: "fixed",
    bottom: "30px",
    display: "flex",
    justifyContent: "center",
    right: "25px",
    alignItems: "center",
    borderRadius: "50%",
    "& > svg": {
      color: "#fff",
      fontSize: "32px",
    },
  },
  faceBookIcon: {
    position: "absolute",
    top: "-160px",
    right: "-25px",

  },
}))

const Facebook = () => {

  return (
    <>
      <MessengerCustomerChat
        pageId="552998445216380"
        appId="349286663561860"

      />
    </>
  )
}

export default function ChatIcons() {
  const classes = useStyles()
  return (
    <div>

      <Facebook />
      <a href="https://wa.me/+66818802868?text=Hey!%20I%20am%20interested%20in%20buying%20something%20from%20your%20fishery." className={classes.WhatsAppIcon}>
        <WhatsAppIcon />
      </a>


    </div>
  )
}
