import React from 'react';
import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'gatsby';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Logo from '../../assets/LOGO.png';
import './Footer.css'
const Footer = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <div className="footer">

            <div className="logo-container">
                <img src={Logo} />
            </div>
            <Grid container spacing={2} className="footer-body" direction="row" justify={isSmall ? "center" : "space-evenly"} alignItems="start">
                <Grid item xs={12} sm={10} md={10} lg={3} xl={3} className="footer-title" >
                    <h5>Providing The Best Fishery Facilities</h5>
                    <p>Bringing to you sustainably caught seafood that is not farmed or genetically modified. Through best farming practices, we ensure hygienically stored seafood delivered to your doorstep.</p>
                    <div className="social-icons">
                        <img src="" />
                        <img src="" />
                        <img src="" />
                        <img src="" />
                        <img src="" />
                    </div>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="footer-nav">
                    <h5>Navigation</h5>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about-us">About</Link></li>
                        <li><Link to="/products">Products</Link></li>
                        <li><a href="/#production">Process</a></li>
                        <li><Link to="/#contact-us">Contact Us</Link></li>
                    </ul>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="footer-nav">
                    <h5>Thailand</h5>
                    <ul>
                        <li>M: <a href="https://wa.me/+66818802868?text=Hey!%20I%20am%20interested%20in%20buying%20something%20from%20your%20fishery.">+66 81 880 2868</a></li>
                        <li>P: <a href="https://wa.me/+6634440503?text=Hey!%20I%20am%20interested%20in%20buying%20something%20from%20your%20fishery.">+66 34 440 503</a></li>
                        <li>F: +66 34 440 504</li>
                        <li><a href="mailto:info@oceanfishery.co.th">info@oceanfishery.co.th</a></li>
                        <li>199/108 Moo 3 Ekachai Road, Nadee, Muang, Samut Sakhon 74000 Thailand</li>
                    </ul>
                </Grid>
                {/* <Grid item xs={12} sm={4} md={3} lg={2} xl={2} className="footer-nav">
                <h5>Pakistan</h5>
                <ul>
                        <li>M: +92 312 2012713</li>
                        <li>P: +92 21 32331930</li>
                        <li>F: +66 34 440 504</li>
                        <li>info@oceanfishery.com.pk</li>
                        <li>Plot No. 7, Boat Building Yard,<br/> West Wharf, Karachi</li>
                    </ul>
                </Grid> */}
            </Grid>
            <div className="footer-bottom">
                <p className="copyright-text">
                    Copyright &copy; 2021 Designer Dev
                </p>
            </div>

        </div>
    )
}

export default Footer;